<template>
  <v-container>
    <h1 v-html="$t('contact_search_masterdata_title')"></h1>
    <v-layout row justify-center>
      <v-overlay v-model="loading" persistent fullscreen id="loading-dialog" content-class="loading-dialog">
        <v-container fill-height>
          <v-layout row justify-center align-center>
            <v-progress-circular indeterminate color="secondary"></v-progress-circular>
          </v-layout>
        </v-container>
      </v-overlay>
    </v-layout>
    <v-row class="row search spacer-md">
      <v-col cols="12" sm="6" md="4" lg="2">
        <search-contact ref="search-contact" @search="search" />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2">
        <search-legal-entity ref="search-legal-entity" @search="search" />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2">
        <search-local-unit ref="search-local-unit" @search="search" />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2">
        <search-farm ref="search-farm" @search="search" />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2">
        <search-dairy ref="search-dairy" @search="search" />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2">
        <searchContactAttributeAndRelation
          name="searchcardAttributeAndRelation"
          store-name="SearchContactAttributesRelationsStore"
          :show-picker="true"
          ref="search-contact-attributes"
          @search="search"
        />
      </v-col>
    </v-row>
    <v-btn color="primary" @click="search"><span v-html="$t('contact_search_masterdata_search')" /></v-btn>
    <v-btn color="secondary" class="margin-left" @click="reset"><span v-html="$t('contact_search_masterdata_reset')" /></v-btn>
    <v-data-table-server
      :headers="fields"
      :items="items"
      :fields="fields"
      :loading="loading"
      :items-length="totalElements"
      :multi-sort="true"
      @update:itemsPerPage="routerPushSize"
      @update:page="routerPushPage"
      @update:sort-by="routerPushDTSort"
      :sort-by="toDTSort(query.sort)"
      :page="getDTPageFromRoute(query.page)"
      :items-per-page="query.size"
      :items-per-page-options="$itemsPerPageOptions"
      density="compact"
    >
      <template v-slot:[`item.edit`]="{ item }">
        <router-link
          :to="{
            name: 'contacts_edit',
            params: { id: item.contactId, tab: 'overview' }
          }"
          class="tableaction link"
        >
          <v-icon dbmblueprimary>mdi-text-box-edit-outline</v-icon>
        </router-link>
      </template>
      <template v-slot:[`item.contact_relation_from`]="{ item }">
        <template v-if="item.contact_relation_from">
          {{ $formatDate(item.contact_relation_from) }}
        </template>
      </template>
      <template v-slot:[`item.contact_relation_until`]="{ item }">
        <template v-if="item.contact_relation_until">
          {{ $formatDate(item.contact_relation_until) }}
        </template>
      </template>
    </v-data-table-server>
  </v-container>
</template>
<script lang="ts">
import { apiURL } from '../../main'
import { Term } from '@/services/term'
import { showError } from '@/services/axios'
import _ from 'lodash'
import { mapActions } from 'vuex'
import searchContact from '@/components/searchCards/searchContact.vue'
import { useSearchContactStore } from '@/store/SearchContactStore'
import searchLegalEntity from '@/components/searchCards/searchLegalEntity.vue'
import { useSearchLegalEntityStore } from '@/store/SearchLegalEntityStore'
import searchFarm from '@/components/searchCards/searchFarm.vue'
import { useSearchFarmStore } from '@/store/SearchFarmStore'
import searchLocalUnit from '@/components/searchCards/searchLocalUnit.vue'
import { useSearchLocalUnitStore } from '@/store/SearchLocalUnitStore'
import searchDairy from '@/components/searchCards/searchDairy.vue'
import { useSearchDairyStore } from '@/store/SearchDairyStore'
import searchContactAttributeAndRelation from '@/components/searchCards/searchContactAttributeAndRelation.vue'
import { privileges } from '@/services/privileges'
import { DTSHeaders } from '@/services/BackendService'
import axios from 'axios'
import { useSearchContactAttributesRelationsStore } from '@/store/SearchContactAttributesRelationsStore'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'searchMasterData',
  data() {
    return {
      items: [],
      totalElements: 0,
      loading: false,

      fields: <DTSHeaders>[
        {
          key: 'edit',
          sortable: false,
          width: '2%'
        },
        {
          title: this.$t('contact_search_independent_contact_id'),
          key: 'contactId',
          sortable: false,
          align: 'left'
        },
        {
          title: this.$t('contact_search_independent_contact_no'),
          key: 'contactNo',
          sortable: false,
          align: 'left'
        },
        {
          title: this.$t('contact_search_independent_name1'),
          key: 'name1',
          sortable: true
        },
        {
          title: this.$t('contact_search_independent_name2'),
          key: 'name2',
          sortable: true
        },
        {
          title: this.$t('contact_search_independent_zip'),
          key: 'zip',
          sortable: true,
          align: 'left'
        },
        {
          title: this.$t('contact_search_independent_locality'),
          key: 'locality',
          sortable: true
        },

        {
          title: this.$t('contact_search_independent_parent_id'),
          key: 'contactParentId',
          sortable: false,
          align: 'left'
        },
        {
          title: this.$t('contact_search_independent_parent_name1'),
          key: 'relationName1',
          sortable: true
        },
        {
          title: this.$t('contact_search_independent_relation_type_name'),
          key: `relationTypeName${this.$getUpLang()}`,
          sortable: true
        },
        {
          title: this.$t('contact_search_independent_relation_from'),
          key: 'relationValidFrom',
          sortable: true
        },
        {
          title: this.$t('contact_search_independent_relation_until'),
          key: 'relationValidUntil',
          sortable: true
        }
      ]
    }
  },
  computed: {
    term(): any {
      return [
        ...Term.buildTermItems(useSearchContactStore(), useSearchContactStore().items),
        ...Term.buildTermItems(useSearchLegalEntityStore(), useSearchLegalEntityStore().items),
        ...Term.buildTermItems(useSearchLocalUnitStore(), useSearchLocalUnitStore().items),
        ...Term.buildTermItems(useSearchFarmStore(), useSearchFarmStore().items),
        ...Term.buildTermItems(useSearchDairyStore(), useSearchDairyStore().items),
        ...Term.buildTermItems(
          useSearchContactAttributesRelationsStore(),
          useSearchContactAttributesRelationsStore().items.filter((item) => item.key != 'contactParentId')
        )
      ]
    },
    query(): any {
      return {
        term: Term.stringify(this.term),
        ...{ contactParentId: useSearchContactAttributesRelationsStore().contactParentId },
        ...this.getJavaPageOptions({ sort: 'name1,asc' })
      }
    }
  },
  components: {
    searchContact,
    searchLegalEntity,
    searchFarm,
    searchLocalUnit,
    searchDairy,
    searchContactAttributeAndRelation
  },
  methods: {
    search() {
      this.$router
        .push({
          path: this.$route.path,
          query: this.query
        })
        .catch((e) => {
          // route duplicated
          this.load()
        })
    },
    async load() {
      console.log('load')
      this.loading = true
      try {
        this.items = []
        const response = await axios.get(apiURL + '/contacts/search', {
          params: { ...this.query, ...{ contactSearchType: 'MASTER_DATA' } }
        })
        const data = await response.data
        this.items = Array.isArray(data.content) ? data.content : []
        this.totalElements = data.totalElements
      } catch (error) {
        showError(error)
      } finally {
        this.loading = false
      }
    },
    reset() {
      this.$refs['search-contact'].reset()
      this.$refs['search-dairy'].reset()
      this.$refs['search-local-unit'].reset()
      this.$refs['search-farm'].reset()
      this.$refs['search-legal-entity'].reset()
      this.$refs['search-contact-attributes'].reset()
    },
    back() {
      this.$router.go(-1)
    },
    $formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    }
  },
  watch: {
    '$route.query': {
      handler(newValue) {
        this.load()
      },
      deep: true
    }
  },
  beforeMount() {
    if (this.$route.query.size) this.load()
  }
})
</script>
